import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Heading,
  } from "@chakra-ui/react";
  import React from "react";
  import AddTenantModal from "./AddTenantModal";
  
  const TenantTableHeader = () => (
    <>
      <Flex>
        <Breadcrumb fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink>Tenants</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex justifyContent="space-between">
        <Heading mb={5}>Registered Tenants</Heading>
        <AddTenantModal>+ Add Tenant</AddTenantModal>
      </Flex>
    </>
  );
  
  export default TenantTableHeader;
  