import { useMsal } from "@azure/msal-react";
import { Avatar, Button, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import SignOutButton from "./SignOutButton";

const NavBar = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <Flex backgroundColor="white" mb={8} w="full">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pt={2}
        pb={2}
        maxW="1900"
        margin="0 auto"
        w="full"
        px={8}
      >
        <Flex>
          <Link mr={4}>
            <RouterLink to="/tenants">
              <Text fontSize="md">Tenants</Text>
            </RouterLink>
          </Link>
          <Link mr={4}>
            <RouterLink to="/configuration">
              <Text fontSize="md">Configuration</Text>
            </RouterLink>
          </Link>
          <Link mr={4}>
            <RouterLink to="/wiki">
              <Text fontSize="md">Wiki</Text>
            </RouterLink>
          </Link>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Avatar size="sm" src={""} name={activeAccount?.name} />
          <Button
            variant="ghost"
            mr={2}
            bg="transparent"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
          >
            {activeAccount?.name}
          </Button>
          <SignOutButton />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavBar;
